import React from "react";
import Nav from "../components/nav";
import Hero from "../sections/hero";
import About from "../sections/about";
import Feature from "../sections/feature";
import Offers from "../sections/offers";
import Products from "../sections/products";
import Gallery from "../sections/gallery";
import Contact from "../sections/contact";
import Footer from "../components/footer";
import styles from "./styles/home.module.css";

const HomePage = () => {
  return (
    <div id="ferrychips-indonesia-homepage" className={styles.main}>
      <Nav componentId="top-navigation" />
      <Hero sectionId="main-screen" />
      <About sectionId="about-us" />
      <Feature
        version="revert"
        imageSrc="/img/img-3.jpg"
        sectionTitle="Our Vision"
        sectionDesc="Our vision is to become the leading exporter of banana chips globally. We endeavor to enhance our product range, expand our customer base, and set new benchmarks in quality and innovation."
      />
      <Feature
        imageSrc="/img/img-1.jpg"
        sectionTitle="Our Mission"
        sectionDesc="Our mission is to provide customers with the finest-quality banana chips while maintaining high ethical standards. We aim to foster long-term relationships with our clients by delivering exceptional products and unparalleled service."
      />
      <Offers sectionId="our-facility" />
      <Products sectionId="our-products" />
      <Gallery sectionId="our-gallery" />
      <Contact sectionId="contact-us" />
      <Footer componentId="bottom-navigation" />
    </div>
  );
};

export default HomePage;
