import React from "react";
import { link } from "../utilities/helper";
import { scrollView } from "@ibrahimstudio/function";
import { IgIcon, FbIcon, InIcon } from "./icons";
import styles from "./styles/footer.module.css";

const Footer = ({ componentId }) => {
  return (
    <footer id={componentId} className={styles.footer}>
      <div className={styles.footerBody}>
        <div className={styles.footerIdentity}>
          {/* <img
            onClick={() => scrollView(-70, "main-screen")}
            className={styles.footerLogoIcon}
            alt={componentId}
            src="/img/footer-logo.png"
          /> */}
          <h1
            onClick={() => scrollView(-70, "main-screen")}
            style={{
              display: "flex",
              position: "relative",
              fontSize: "var(--font-medium)",
              fontFamily: "var(--font-inter)",
              color: "var(--color-text)",
            }}
          >
            Ferrychips
          </h1>
          <p className={styles.footerAddress}>
            Jl. P. Tidore no.01 LK.III, Jagabaya II, RT 006, Kec. Way Halim, Kota Bandar Lampung, Lampung, Indonesia
          </p>
        </div>
        <div className={styles.footerMenu}>
          <h1 className={styles.footerMenuTitle}>Navigation</h1>
          <div className={styles.footerMenuUl}>
            <div className={styles.footerMenuLi} onClick={() => scrollView(-70, "main-screen")}>
              Home
            </div>
            <div className={styles.footerMenuLi} onClick={() => scrollView(-70, "our-facility")}>
              Offers
            </div>
            <div className={styles.footerMenuLi} onClick={() => scrollView(-70, "our-products")}>
              Products
            </div>
            <div className={styles.footerMenuLi} onClick={() => scrollView(-70, "our-gallery")}>
              Gallery
            </div>
          </div>
        </div>
        <div className={styles.footerMenu}>
          <h1 className={styles.footerMenuTitle}>Company</h1>
          <div className={styles.footerMenuUl}>
            <div className={styles.footerMenuLi}>Legal</div>
            <div className={styles.footerMenuLi}>Careers</div>
            <div className={styles.footerMenuLi}>Partnership</div>
            <div className={styles.footerMenuLi} onClick={() => link("https://maps.app.goo.gl/NCFBbWrcRYusFzsY7")}>
              Location
            </div>
          </div>
        </div>
        <div className={styles.footerMenu}>
          <h1 className={styles.footerMenuTitle}>Socials</h1>
          <div className={styles.footerMenuIcons}>
            <div className={styles.socialIcon}>
              <div
                style={{
                  display: "flex",
                  width: "var(--pixel-25)",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IgIcon width="100%" color="var(--color-text)" />
              </div>
            </div>
            <div className={styles.socialIcon}>
              <div
                style={{
                  display: "flex",
                  width: "var(--pixel-25)",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FbIcon width="100%" color="var(--color-text)" />
              </div>
            </div>
            <div className={styles.socialIcon}>
              <div
                style={{
                  display: "flex",
                  width: "var(--pixel-25)",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <InIcon width="100%" color="var(--color-text)" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <h1 className={styles.footerFooter}>© 2024 Ferrychips. All rights reserved.</h1>
    </footer>
  );
};

export default Footer;
