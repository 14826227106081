export const productsData = [
  {
    imageSrc: "/img/products-1.jpg",
    prodTitle: "Classic Original Banana Chips",
    prodDesc:
      "Crispy chips made from perfectly ripe bananas, lightly salted for a delightful snacking experience.",
  },
  {
    imageSrc: "/img/products-2.jpg",
    prodTitle: "Salty Banana Chips",
    prodDesc:
      "A tantalizing blend of salty flavors that bring out the natural taste of bananas.",
  },
  {
    imageSrc: "/img/products-3.jpg",
    prodTitle: "Chocolate-coated Banana Chips",
    prodDesc:
      "Irresistible banana chips coated with smooth and velvety milk chocolate, creating a delightful fusion of flavors.",
  },
  {
    imageSrc: "/img/products-4.jpg",
    prodTitle: "Exotic Fruit Mix",
    prodDesc:
      "A tropical medley of banana chips combined with other delectable dried fruits, offering a burst of flavors in every bite.",
  },
];

export const galleryData = [
  {
    imageSrc: "/img/img-1.jpg",
  },
  {
    imageSrc: "/img/img-2.jpg",
  },
  {
    imageSrc: "/img/img-3.jpg",
  },
  {
    imageSrc: "/img/img-4.jpg",
  },
];
