import React from "react";
import { link } from "../utilities/helper";
import { PhoneIcon, MailIcon, MapsIcon } from "../components/icons";
import styles from "./styles/contact.module.css";
import { Button } from "@ibrahimstudio/button";

const Contact = ({ sectionId }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("form submitted");
  };

  return (
    <section id={sectionId} section-view-id={sectionId} className={styles.contact}>
      <div className={styles.contactHeadWrap}>
        <div className={styles.contactHead}>
          <h1 className={styles.contactTitle}>Experience the Finest Banana Chips. Contact us today!</h1>
          <p className={styles.contactDesc}>
            For inquiries, partnerships, or to place an order, please fill in the form below, and our friendly team will get back to you promptly.
            Join us in creating unbeatable snacking options!
          </p>
        </div>
        <form className={styles.contactForm} onSubmit={handleSubmit}>
          <div className={styles.inputWrap}>
            <div className={styles.inputBody}>
              <label className={styles.inputLabel}>Your Name</label>
              <div className={styles.inputField}>
                <input className={styles.inputFieldInput} placeholder="e.g. John Doe" type="text" />
              </div>
            </div>
            <div className={styles.inputBody}>
              <label className={styles.inputLabel}>Your Phone Number</label>
              <div className={styles.inputField}>
                <input className={styles.inputFieldInput} placeholder="0885 xxxx" type="text" />
              </div>
            </div>
          </div>
          <div className={styles.inputWrap}>
            <div className={styles.inputBody}>
              <label className={styles.inputLabel}>Your Email</label>
              <div className={styles.inputField}>
                <input className={styles.inputFieldInput} placeholder="myaccount@mail.com" type="text" />
              </div>
            </div>
          </div>
          <div className={styles.inputWrap}>
            <div className={styles.inputBody}>
              <label className={styles.inputLabel}>Message</label>
              <div className={styles.inputField1}>
                <textarea className={styles.inputFieldInput1} placeholder="Input your message here ..." />
              </div>
            </div>
          </div>
          <Button id="send-contact-form" type="submit" buttonText="Send Message" radius="sm" />
        </form>
      </div>
      <div className={styles.contactBody}>
        <div className={styles.contactCard}>
          <div
            style={{
              display: "flex",
              width: "var(--pixel-35)",
              alignItems: "center",
              justifyContent: "center",
              color: "inherit",
            }}
          >
            <PhoneIcon width="100%" height="100%" />
          </div>
          <div className={styles.contactcardHead}>
            <h1 className={styles.contactcardTitle}>Phone</h1>
            <h2 className={styles.contactcardSubtitle}>+62 895 6006 88111</h2>
          </div>
        </div>
        <div className={styles.contactCard} onClick={() => link("mailto:contact@ferrychips.com")}>
          <div
            style={{
              display: "flex",
              width: "var(--pixel-35)",
              alignItems: "center",
              justifyContent: "center",
              color: "inherit",
            }}
          >
            <MailIcon width="100%" height="100%" />
          </div>
          <div className={styles.contactcardHead}>
            <h1 className={styles.contactcardTitle}>Email</h1>
            <h2 className={styles.contactcardSubtitle}>mayferry80@gmail.com</h2>
          </div>
        </div>
        <div className={styles.contactCard} onClick={() => link("https://maps.app.goo.gl/NCFBbWrcRYusFzsY7")}>
          <div
            style={{
              display: "flex",
              height: "var(--pixel-28)",
              alignItems: "center",
              justifyContent: "center",
              color: "inherit",
            }}
          >
            <MapsIcon width="100%" height="100%" />
          </div>
          <div className={styles.contactcardHead}>
            <h1 className={styles.contactcardTitle}>Location</h1>
            <h2 className={styles.contactcardSubtitle}>See Location on Maps</h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
