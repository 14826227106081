import React from "react";
import styles from "./styles/about.module.css";

const About = ({ sectionId }) => {
  return (
    <section
      id={sectionId}
      section-view-id={sectionId}
      className={styles.about}
    >
      <div className={styles.aboutHead}>
        <h2 className={styles.aboutSubtitle}>ABOUT US</h2>
        <h1 className={styles.aboutTitle}>About Ferrychips</h1>
        <p className={styles.aboutDesc}>
          Ferrychips is dedicated to delivering delicious and healthy snacking
          options globally. With our expertise in the export sector, we have
          established ourselves as a leading banana chips manufacturer and
          exporter.
        </p>
      </div>
      <div className={styles.aboutBody}>
        <iframe
          className={styles.videoEmbed}
          src="https://www.youtube.com/embed/fzjo9_yZ3Vc"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </section>
  );
};

export default About;
