import React from "react";
import { StarIcon, NatureIcon, GlobeIcon } from "../components/icons";
import OffersCard from "../components/offers-card";
import styles from "./styles/offers.module.css";

const Offers = ({ sectionId }) => {
  return (
    <section
      id={sectionId}
      section-view-id={sectionId}
      className={styles.offers}
    >
      <div className={styles.offersHead}>
        <h1 className={styles.offersTitle}>Why Choose Ferrychips?</h1>
        <p className={styles.offersDesc}>
          At Ferrychips, we pride ourselves on being the preferred choice for
          international customers. Our commitment to quality, reliability, and
          customer satisfaction sets us apart. Choose us for these advantages:
        </p>
      </div>
      <div className={styles.offersBody}>
        <OffersCard
          iconContent={<StarIcon width="100%" height="100%" />}
          cardTitle="Premium Quality"
          cardDesc="Our banana chips are made from carefully selected bananas and follow strict quality control processes."
        />
        <OffersCard
          iconContent={<GlobeIcon width="100%" height="100%" />}
          cardTitle="International Reach"
          cardDesc="We have a vast network that allows us to serve customers worldwide, ensuring timely deliveries."
        />
        <OffersCard
          iconContent={<NatureIcon width="100%" height="100%" />}
          cardTitle="Health-conscious"
          cardDesc="Our banana chips are cooked in healthy oil and contain no artificial additives, making them a guilt-free snack option."
        />
      </div>
    </section>
  );
};

export default Offers;
