import React from "react";
import { productsData } from "../utilities/data";
import ProdCard from "../components/prod-card";
import styles from "./styles/products.module.css";

const Products = ({ sectionId }) => {
  return (
    <section
      id={sectionId}
      section-view-id={sectionId}
      className={styles.products}
    >
      <div className={styles.productsHead}>
        <h2 className={styles.productsSubtitle}>OUR PRODUCTS</h2>
        <h1 className={styles.productsTitle}>
          Explore Our Delicious Banana Chips
        </h1>
        <p className={styles.productsDesc}>
          Our range of banana chips includes a variety of flavors, each offering
          a unique taste experience. Discover our exceptional products:
        </p>
      </div>
      <div className={styles.productsBody}>
        {productsData.map((product, index) => (
          <ProdCard
            key={index}
            version={index % 2 === 0 ? "" : "revert"}
            imageSrc={product.imageSrc}
            prodTitle={product.prodTitle}
            prodDesc={product.prodDesc}
          />
        ))}
      </div>
    </section>
  );
};

export default Products;
